<template>
  <b-card>
    <ValidationObserver>
      <form @submit.prevent="saveItem">
        <b-row>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="title">Judul:</label>
              <validation-provider
                name="title"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="title"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.title"
                  placeholder="Judul Webinar"
                />
                <small
                  v-for="(validation, index) in validations.title"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="type">Headline:</label>
              <validation-provider name="type" rules="required">
                <div class="demo-inline-spacing" style="margin-top: 8px">
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="headlineOptions"
                      class="custom-control-input"
                      type="radio"
                      id="radio1"
                      value="true"
                      v-model="formPayload.is_headline"
                    />
                    <label class="custom-control-label" for="radio1">
                      Yes
                    </label>
                  </div>
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="headlineOptions"
                      class="custom-control-input"
                      type="radio"
                      id="radio2"
                      value="false"
                      v-model="formPayload.is_headline"
                    />
                    <label class="custom-control-label" for="radio2">
                      No
                    </label>
                  </div>
                </div>
                <small
                  v-for="(validation, index) in validations.is_headline"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="type">Status:</label>
              <validation-provider name="type" rules="required">
                <div class="demo-inline-spacing" style="margin-top: 8px">
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="statusOptions"
                      class="custom-control-input"
                      type="radio"
                      id="radio3"
                      value="publish"
                      v-model="formPayload.status"
                    />
                    <label class="custom-control-label" for="radio3">
                      Publish
                    </label>
                  </div>
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="statusOptions"
                      class="custom-control-input"
                      type="radio"
                      id="radio4"
                      value="unpublish"
                      v-model="formPayload.status"
                    />
                    <label class="custom-control-label" for="radio4">
                      Unpublish
                    </label>
                  </div>
                </div>
                <small
                  v-for="(validation, index) in validations.type"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="publishDate">Publish Date:</label>
              <validation-provider name="publishDate" rules="required">
                <flat-pickr
                  id="publishDate"
                  v-model="formPayload.publish_date"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss' }"
                />
                <small
                  v-for="(validation, index) in validations.publish_date"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="image">Image:</label><br />
              <p class="image__hint"><i>Only Jpg, Image</i></p>
              <b-form-file
                id="image"
                class="mb-2"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept="image/jpeg, image/png"
                @change="changeFile($event, 'image')"
              />
              <small>Current file:</small><br />
              <img
                v-if="image_url !== null"
                :src="image_url"
                class="media_file"
              />
            </div>
            <div class="form-group">
              <label for="signature">Signature:</label><br />
              <p class="image__hint"><i>Only Jpg, Image</i></p>
              <b-form-file
                id="signature"
                class="mb-2"
                accept="image/jpeg, image/png"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="changeFile($event, 'signature')"
              />
              <small>Current file:</small><br />
              <img
                v-if="signature_url !== null"
                :src="signature_url"
                class="media_file"
              />
            </div>
            <div class="form-group">
              <label for="metaKeyword"> Meta Keyword: </label>
              <input
                id="metaKeyword"
                class="form-control"
                v-model="formPayload.meta_keyword"
                placeholder="Meta Keyword"
              />
            </div>
            <div class="form-group">
              <label for="metaDescription"> Meta Description: </label>
              <input
                id="metaDescription"
                class="form-control"
                v-model="formPayload.meta_description"
                placeholder="Meta Description"
              />
            </div>
            <div class="form-group">
              <label for="module">URL Module:</label>
              <validation-provider
                name="module"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="module"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.additional_information.url_module"
                  placeholder="URL Module"
                />
                <small
                  v-for="(validation, index) in validations[
                    'additional_information.url_module'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="content">Content:</label>
              <validation-provider name="content" rules="required">
                <quill-editor id="content" v-model="formPayload.content" />
                <small
                  v-for="(validation, index) in validations.content"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="location">Location:</label>
              <validation-provider
                name="location"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="location"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.additional_information.location"
                  placeholder="Location"
                />
                <small
                  v-for="(validation, index) in validations[
                    'additional_information.location'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="participant">Participant:</label>
              <validation-provider
                name="participant"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="participant"
                  type="number"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.additional_information.participant"
                  placeholder="Jumlah partisipasi yang ikut"
                />
                <small
                  v-for="(validation, index) in validations[
                    'additional_information.participant'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="startDate">Start Date:</label>
              <validation-provider name="startDate" rules="required">
                <flat-pickr
                  id="startDate"
                  v-model="formPayload.additional_information.start_date"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss' }"
                />
                <small
                  v-for="(validation, index) in validations[
                    'additional_information.start_date'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="endDate">End Date:</label>
              <validation-provider name="endDate" rules="required">
                <flat-pickr
                  id="endDate"
                  v-model="formPayload.additional_information.end_date"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss' }"
                />
                <small
                  v-for="(validation, index) in validations[
                    'additional_information.end_date'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation.end_date }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="link">Link:</label>
              <validation-provider
                name="link"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="link"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.additional_information.link"
                  placeholder="Link"
                />
                <small
                  v-for="(validation, index) in validations[
                    'additional_information.link'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="youtubeId">Youtube ID:</label>
              <input
                id="youtubeId"
                type="text"
                class="form-control"
                v-model="formPayload.additional_information.youtube_id"
                placeholder="Link"
              />
            </div>
            <div class="form-group">
              <label for="speakers">Speakers:</label>
              <validation-provider
                name="moderator"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <v-select
                  id="speakers"
                  label="name"
                  v-model="speakerList"
                  :options="speakerData"
                  placeholder="-- Pilih Speaker --"
                  multiple
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @keyup.stop.native="searchSpeaker"
                />
                <small
                  v-for="(validation, index) in validations.speakers"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="moderator">Moderator:</label>
              <validation-provider
                name="moderator"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <v-select
                  id="moderator"
                  label="name"
                  v-model="moderatorList"
                  :options="moderatorData"
                  placeholder="-- Pilih Moderator --"
                  multiple
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @keyup.stop.native="searchModerator"
                />
                <small
                  v-for="(validation, index) in validations.moderators"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
          </b-col>
        </b-row>

        <b-col cols="12" class="p-0 mt-2">
          <button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary"
            :disabled="isLoading"
          >
            {{ isLoading ? "Submitting..." : "Submit" }}
          </button>
          &nbsp; &nbsp;
          <a
            @click="handleBackButton()"
            variant="secondary"
            class="btn waves-effect waves-float waves-light btn-secondary"
          >
            Cancel
          </a>
        </b-col>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import _ from "lodash";
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BRow,
  BCol,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormFile,
    quillEditor,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  data() {
    return {
      required,
      isLoading: false,
      formPayload: {
        title: "",
        is_headline: "",
        status: "",
        publish_date: "",
        image: "",
        signature: "",
        meta_keyword: "",
        meta_description: "",
        content: "",
        additional_information: {
          location: "",
          participant: "",
          start_date: "",
          end_date: "",
          link: "",
          youtube_id: "",
          url_module: "",
        },
        moderators: [],
        speakers: [],
      },
      speakerList: [],
      speakerData: [],
      moderatorList: [],
      moderatorData: [],
      image_url: null,
      signature_url: null,
      validations: "",
      // Must be an array reference!
    };
  },

  mounted() {
    this.loadSpeaker();
    this.loadModerator();
  },

  created() {
    this.loadWebinar();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    loadWebinar() {
      const params = this.$route.params.uuid;
      this.$http
        .get("/api/v1/admin/communication-activities/webinar/" + params)
        .then((response) => {
          this.formPayload = response.data.data;
          this.image_url = this.formPayload.image;
          this.signature_url = this.formPayload.signature;
          this.speakerList = this.formPayload.speakers;
          this.moderatorList = this.formPayload.moderators;
        });
    },
    changeFile(event, name) {
      if (name === "image") {
        this.formPayload.image = event.target.files[0];
        this.showPhoto(event.target.files[0], name);
      } else {
        this.formPayload.signature = event.target.files[0];
        this.showPhoto(event.target.files[0], name);
      }
    },
    showPhoto(file, name) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (name === "image") {
          this.image_url = e.target.result;
        } else {
          this.signature_url = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    searchSpeaker: _.debounce(function (e) {
      const search = e.target.value;
      this.loadSpeaker(search);
    }, 300),

    searchModerator: _.debounce(function (e) {
      const search = e.target.value;
      this.loadModerator(search);
    }, 300),

    loadSpeaker(search) {
      this.$http
        .get("/api/v1/admin/users?group=adminwebinar", {
          params: {
            search: search != null ? search : null,
          },
        })
        .then((response) => {
          this.speakerData = response.data.data.items;
        });
    },

    loadModerator(search) {
      this.$http
        .get("/api/v1/admin/users?group=moderator", {
          params: {
            search: search != null ? search : null,
          },
        })
        .then((response) => {
          this.moderatorData = response.data.data.items;
        });
    },
    saveItem() {
      this.isLoading = true;
      const params = this.$route.params.uuid;
      let speakersId = [];
      let moderatorsId = [];
      this.speakerList.forEach((speaker) => speakersId.push(speaker.uuid));
      this.formPayload.speakers = speakersId;
      this.moderatorList.forEach((speaker) => moderatorsId.push(speaker.uuid));
      this.formPayload.moderators = moderatorsId;
      const payload = new FormData();
      payload.append("title", this.formPayload.title);
      payload.append("is_headline", this.formPayload.is_headline);
      payload.append("status", this.formPayload.status);
      payload.append("publish_date", this.formPayload.publish_date);
      payload.append("image", this.formPayload.image);
      payload.append("signature", this.formPayload.signature);
      payload.append("meta_keyword", this.formPayload.meta_keyword);
      payload.append("meta_description", this.formPayload.meta_description);
      payload.append("content", this.formPayload.content);
      payload.append(
        "additional_information[location]",
        this.formPayload.additional_information.location
      );
      payload.append(
        "additional_information[participant]",
        this.formPayload.additional_information.participant
      );
      payload.append(
        "additional_information[start_date]",
        this.formPayload.additional_information.start_date
      );
      payload.append(
        "additional_information[end_date]",
        this.formPayload.additional_information.end_date
      );
      payload.append(
        "additional_information[link]",
        this.formPayload.additional_information.link
      );
      payload.append(
        "additional_information[youtube_id]",
        this.formPayload.additional_information.youtube_id
      );
      payload.append(
        "additional_information[url_module]",
        this.formPayload.additional_information.url_module
      );
      payload.append("speakers[]", this.formPayload.speakers);
      payload.append("moderators[]", this.formPayload.moderators);

      this.$http
        .post(
          `/api/v1/admin/communication-activities/webinar/${params}`,
          payload,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "Webinar Berhasil diedit!");
          this.$router.push({ name: "webinar" });
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error.response.data.meta.validations);
          if (error.response.data.meta.validations) {
            console.log("asup");
            this.validations = error.response.data.meta.validations;
            errorNotification(
              this,
              "End Date",
              this.validations["additional_information.end_date"].join("")
            );
            errorNotification(
              this,
              "Link",
              this.validations["additional_information.link"].join("")
            );
            errorNotification(
              this,
              "Location",
              this.validations["additional_information.location"].join("")
            );
            errorNotification(
              this,
              "Participant",
              this.validations["additional_information.participant"].join("")
            );
            errorNotification(
              this,
              "Start Date",
              this.validations["additional_information.start_date"].join("")
            );
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.media_file {
  width: 400px;
  height: 300px;
  object-fit: cover;
}
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
</style>
